/** ***************************************************************************
 * Ecommerce / Organisms / Cart Item
 *************************************************************************** */

class CartItemComponent {
  constructor(element) {
    this.element = element;
    this.trashButton = this.element.querySelector('.cn-c-button--trash');
    this.deletionCheckbox = this.element.querySelector(
      '.hidden-fields input[type="checkbox"]'
    );

    if (this.trashButton) {
      this.trashButton.onclick = () => {
        this.deletionCheckbox.checked = true;
      };
    }
  }
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-cart_item';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnCartItemComponent = new CartItemComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnCartItemComponent) {
        element.cnCartItemComponent.destroy();
        element.cnCartItemComponent = undefined;
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});
