/** ***************************************************************************
 * Ecommerce / Organisms / Products Slider
 *************************************************************************** */

import { Intersection } from '@splidejs/splide-extension-intersection';

class ProductsSliderComponent {
  constructor(element) {
    this.element = element;
    this.slider = this.element.querySelector('.splide');
    this.thumbnails = this.slider.querySelectorAll('.thumbnails .thumbnail');

    this.slider.addEventListener('sliderComponent:init', () => {
      this.initializeSlider();
    });
    this.thumbnails.forEach((thumbnail, index) => {
      thumbnail.addEventListener('click', () => {
        this.slider.cnSlider.splide.go(index);
      });
    });
  }

  initializeSlider() {
    this.options = {
      type: 'loop',
      arrows: true,
      pagination: false,
      perPage: 1,
      // drag: 'free',
      // gap: 'var(--splide-items-gap)',
      speed: 700,
      interval: 5500,
      autoplay: 'pause',
      // autoScroll: false,
      intersection: {
        inView: {
          autoplay: 'pause',
        },
        outView: {
          autoplay: false,
        },
      },
    };
    this.extensions = { Intersection };

    this.slider.cnSlider.initialize(this.options, this.extensions);

    this.slider.cnSlider.splide.Components.Slides.getAt(0).slide.classList.add(
      'will-active'
    );

    this.slider.cnSlider.splide.on('mounted move', (index) => {
      this.activeBar = this.thumbnails[index].querySelector(
        '.my_splide__progress__bar'
      );
      this.slider.cnSlider.splide.Components.Slides.getAt(
        index
      ).slide.classList.add('will-active');
    });

    this.slider.cnSlider.splide.on('moved', (index) => {
      this.slider.cnSlider.splide.Components.Slides.getAt(
        index
      ).slide.classList.remove('will-active');
    });

    this.slider.cnSlider.splide.on('autoplay:playing', (rate) => {
      if (this.activeBar) {
        this.activeBar.style.width = `${rate * 100}%`;
      } else {
        this.activeBar = this.thumbnails[0].querySelector(
          '.my_splide__progress__bar'
        );
      }
    });
  }
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-products_slider';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnProductsSliderComponent = new ProductsSliderComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnProductsSliderComponent) {
        element.cnProductsSliderComponent.destroy();
        element.cnProductsSliderComponent = undefined;
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});
