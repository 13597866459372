/** ***************************************************************************
 * Ecommerce / Organisms / Cart Summary
 *************************************************************************** */

class CartSummaryComponent {
  constructor(element) {
    this.element = element;
    this.promotionalCodeTrigger =
      this.element.querySelector('.promotional-code');
    this.form = this.element.querySelector('form');

    this.setupevEntListeners();

    if (this.form) {
      this.cancelButton = this.form.querySelector('.cancel');
      this.promotionalCodeTrigger.addEventListener('click', this.open);
      this.cancelButton.addEventListener('click', this.close);
    }
  }

  destroy() {
    this.promotionalCodeTrigger.removeEventListener('click', this.open);
    this.cancelButton.removeEventListener('click', this.close);
  }

  setupevEntListeners() {
    this.open = () => {
      this.promotionalCodeTrigger.classList.toggle('is-open');
      this.form.classList.toggle('is-open');
      this.form.querySelector('input').focus();
    };
    this.close = () => {
      this.promotionalCodeTrigger.classList.remove('is-open');
      this.form.classList.remove('is-open');
      this.promotionalCodeTrigger.focus();
    };
  }
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-cart_summary';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnCartSummaryComponent = new CartSummaryComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnCartSummaryComponent) {
        element.cnCartSummaryComponent.destroy();
        element.cnCartSummaryComponent = undefined;
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});
