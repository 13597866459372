/** ***************************************************************************
 * Ecommerce / Organisms / Members Slider Section
 *************************************************************************** */

import { Intersection } from '@splidejs/splide-extension-intersection';

class MembersSliderSectionComponent {
  constructor(element) {
    this.element = element;
    this.slider = this.element.querySelector('.splide');

    this.slider.addEventListener('sliderComponent:init', () => {
      this.initializeSlider();
    });
  }

  initializeSlider() {
    this.options = {
      arrows: true,
      pagination: false,
      autoWidth: true,
      gap: 'var(--splide-items-gap)',
      // perPage: 3,
    };
    this.extensions = { Intersection };

    this.slider.cnSlider.initialize(this.options, this.extensions);
  }
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-members';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnMembersSliderSectionComponent =
        new MembersSliderSectionComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnMembersSliderSectionComponent) {
        element.cnMembersSliderSectionComponent.destroy();
        element.cnMembersSliderSectionComponent = undefined;
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});
