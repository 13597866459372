/** ***************************************************************************
 * Ecommerce / Atoms / Button / Roadmap
 *************************************************************************** */

class ConchigliaRoadmapButton {
  constructor(element) {
    this.element = element;
    this.text = this.element.querySelector('span');
    this.computeTextSize();

    window.addEventListener('resize', () => {
      this.computeTextSize();
    });
  }

  computeTextSize() {
    this.text.style.fontSize = 30 + 'px';
    this.text.style.fontWeight = 'normal';
    let size = parseInt(
      getComputedStyle(this.text).getPropertyValue('font-size')
    );
    const parentWidth = parseInt(
      getComputedStyle(this.text.parentElement).getPropertyValue('width')
    );
    while (this.text.offsetWidth + 56 > parentWidth) {
      size -= 1;
      this.text.style.fontSize = size + 'px';
    }
    if (size >= 20) {
      this.text.style.fontWeight = 'bold';
    }
  }
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-u-button.cn-m-roadmap';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnRoadmapButton = new ConchigliaRoadmapButton(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnRoadmapButton) {
        element.cnRoadmapButton.destroy();
        element.cnRoadmapButton = undefined;
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { ConchigliaRoadmapButton };
