/** ***************************************************************************
 * Ecommerce / Organisms / Auth / Header
 *************************************************************************** */

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';

class AuthAreaHeader {
  constructor(element) {
    this.element = element;
    this.toggle = document.getElementById('cn-c-auth-header__toggle');
    this.setupEventListeners();

    this.toggle.onchange = this.onchange;
    window.addEventListener('resize', this.onresize); // FIXME: use a resize observer
  }

  destroy() {
    window.removeEventListener(this.onresize);
    this.toggle.onchange = undefined;
  }

  setupEventListeners() {
    this.onresize = () => {
      if (window.matchMedia('(min-width: 50rem)').matches) {
        this.toggle.checked = false;
        this.toggle.onchange = undefined;
        enableBodyScroll(this.element);
      } else {
        this.toggle.onchange = this.onchange;
      }
    };
    this.onchange = (event) => {
      if (event.target.checked) {
        disableBodyScroll(this.element, { reserveScrollBarGap: true });
        window.addEventListener;
      } else {
        enableBodyScroll(this.element);
      }
    };
  }
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-auth-header';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnAuthAreaHeader = new AuthAreaHeader(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnAuthAreaHeader) {
        element.cnAuthAreaHeader.destroy();
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { AuthAreaHeader };
