/** ***************************************************************************
 * Ecommerce / Molecules / Accordion
 *************************************************************************** */

class AccordionComponent {
  constructor(element) {
    this.element = element;
    this.instance = this.element.classList.contains('cn-c-accordion--cms_page')
      ? 'cms_page'
      : 'base';
    this.toggles = this.element.querySelectorAll(
      'li > input[name="cn-c-accordion__item"]'
    );
    this.labels = this.element.querySelectorAll(
      'li > div .accordion-header label'
    );
    this.strategy = this.element.classList.contains('single')
      ? 'single'
      : 'multiple';

    this.setupEventListeners();
  }

  setupEventListeners() {
    this.toggles.forEach((toggle) => {
      toggle.addEventListener('change', (event) => {
        if (this.strategy === 'single') {
          this.closeAllBut(event.target);
        }
        this.toggleChanged(event.target);
      });
    });
    if (this.instance === 'cms_page') {
      this.labels.forEach((label) => {
        label.addEventListener('mouseover', this.toggleHoverClass.bind(this));
        label.addEventListener('mouseout', this.toggleHoverClass.bind(this));
      });
    }
  }

  toggleHoverClass(event) {
    const toggle = { id: event.target.getAttribute('for') };
    const button = this.getButtonIcon(toggle);
    button.classList.toggle('hoverized');
  }

  closeAllBut(target) {
    this.toggles.forEach((toggle) => {
      if (toggle !== target) {
        this.close(toggle);
      }
    });
  }

  toggleChanged(toggle) {
    if (toggle.checked) {
      this.open(toggle);
    } else {
      this.close(toggle);
    }
  }

  open(toggle) {
    const labelSvgUse = this.getButtonIconSvg(toggle);

    labelSvgUse.setAttribute(
      'href',
      labelSvgUse.getAttribute('href').replace('#plus', '#minus')
    );

    const panel = this.element.querySelector(`.${toggle.id}`);
    panel.style.maxHeight = panel.scrollHeight + 'px';

    // setTimeout(() => {
    //   panel.scrollIntoView();
    // }, 150);
  }

  close(toggle) {
    toggle.checked = false;
    const panel = this.element.querySelector(`.${toggle.id}`);
    panel.style.maxHeight = null;

    const labelSvgUse = this.getButtonIconSvg(toggle);
    labelSvgUse.setAttribute(
      'href',
      labelSvgUse.getAttribute('href').replace('#minus', '#plus')
    );
  }

  getButtonIcon(toggle) {
    if (this.instance == 'cms_page') {
      return this.element.querySelector(
        `input[id=${toggle.id}] ~ .cn-c-button.plus`
      );
    } else {
      return this.element.querySelector(
        `li > label[for=${toggle.id}] > .icon-wrapper`
      );
    }
  }

  getButtonIconSvg(toggle) {
    return this.getButtonIcon(toggle).querySelector(`svg > use`);
  }

  destroy() {}
}

window.addEventListener('conchiglia:init', () => {
  const selector = '.cn-c-accordion';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.cnAccordionComponent = new AccordionComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.cnAccordionComponent) {
        element.cnAccordionComponent.destroy();
      }
    });
  }

  window.CONCHIGLIA.register(selector, initialize, destroy);

  initialize();
});

export { AccordionComponent };
